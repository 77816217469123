import { useState } from "react"
import { Link } from "react-router-dom"
import mainLogo from "../../../assets/main-logo.png"
import InputGenerator from "../../../components/Login/InputGenerator"
import { login } from "../../../auth/auth"
import "../SignInPage.css"
import { loginLinkStyle } from "../../../styles"

function SignInPage() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  async function signIn() {
    login(email, password).catch((err) => {
      console.log(err.message)
      const errorMessages = {
        "Firebase: Error (auth/wrong-password).": "Wrong Password",
        "Firebase: Error (auth/invalid-email).": "Invalid Email",
        "Firebase: Error (auth/user-not-found).": "User Not Found",
      }
      if (errorMessages[err.message]) {
        alert(errorMessages[err.message])
      } else {
        alert("Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.")
      }
    })
  }

  function handleChange(e) {
    const name = e.target.name
    const value = e.target.value
    switch (name) {
      case "email":
        setEmail(value)
        break
      case "password":
        setPassword(value)
        break
      default:
        break
    }
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 sign-in-background">
      <div className=" d-flex flex-column justify-content-center align-items-center sign-in">
        <img src={mainLogo} alt="Main logo" className="w-50 m-2" />
        <InputGenerator
          inputs={[
            {
              label: "Email",
              type: "email",
              value: email,
            },
            {
              label: "Password",
              type: "password",
              value: password,
            },
          ]}
          handleChange={handleChange}
        />
        <button className="sign-in-button" onClick={signIn}>
          Login
        </button>
      </div>
      <br></br>

      <Link to={"/sign-up"} style={loginLinkStyle}>
        Register
      </Link>
      <Link to={"/reset-password"} style={loginLinkStyle}>
        Forgot Password?
      </Link>
    </div>
  )
}

export default SignInPage
