import React, { useEffect, useRef, useState } from "react"
import { Accordion, FormCheck } from "react-bootstrap"
import { AiOutlineCamera, AiOutlineEdit } from "react-icons/ai"
import { RiDeleteBin2Line } from "react-icons/ri"
import { useSelector } from "react-redux"
import { selectRole, selectUserFirstName, selectUserLastName } from "../../features/user/userSlice"
import { createNote, deleteCustomer, deleteNote, editNote, editDbPrimaryNote } from "../../firestore/firestore"
import WarningModal from "../Modals/WarningModal"
import InfoModal from "../Modals/InfoModal"
import { v4 as uuidv4 } from "uuid"
import "./CustomerInfo.css"

export default function CustomerInfo({ customer, i, refreshCallback }) {
  const [showModal, setShowModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [modalInfo, setModalInfo] = useState([])
  const [notes, setNotes] = useState([]) // array of notes {note: "note", createdAt: "date"}
  const [confirmDelete, setConfirmDelete] = useState() // function to delete customer or note
  const [editingNoteId, setEditingNoteId] = useState(null) // Track the ID of the note being edited
  const userRole = useSelector(selectRole)
  const firstName = useSelector(selectUserFirstName)
  const lastName = useSelector(selectUserLastName)
  const textareaRef = React.createRef()
  const [warningModalInfo, setWarningModalInfo] = useState([])
  const [primaryNote, setPrimaryNote] = useState("")
  const [editPrimaryNote, setEditPrimaryNote] = useState(false)
  const noteRefs = useRef([])

  useEffect(() => {
    if (customer.notes) {
      const orderedNotes = customer.notes.slice(-10).reverse()
      setNotes(orderedNotes)
      noteRefs.current = orderedNotes.map((_, i) => noteRefs.current[i] || React.createRef())
    }
    if (customer.primaryNote) {
      setPrimaryNote(customer.primaryNote)
    }
  }, [customer.notes, customer.primaryNote])

  useEffect(() => {
    // Focus when a note is being edited
    if (editingNoteId !== null) {
      const noteIndex = notes.findIndex((note) => note.id === editingNoteId)
      if (noteRefs.current[noteIndex] && noteRefs.current[noteIndex].current) {
        focusTextAreaAndSetCursorToEnd(noteRefs.current[noteIndex])
      }
    }
  }, [editingNoteId, notes])

  const catSet = new Set()
  const sortBy = (array, arrayProperty) =>
    array.sort((first, second) => {
      if (first[arrayProperty] < second[arrayProperty]) {
        return -1
      }
      if (first[arrayProperty] > second[arrayProperty]) {
        return 1
      }
      return 0
    })

  function focusTextAreaAndSetCursorToEnd(textAreaRef) {
    const textArea = textAreaRef.current
    if (textArea) {
      textArea.focus()
      // Set cursor position to end of text
      const length = textArea.value.length
      textArea.setSelectionRange(length, length)
    }
  }

  // handles deleting a customer or note
  function handleDelete(type /*customer or note*/, note = null) {
    if (type === "customer") {
      setWarningModalInfo([/*Title*/ ` ${customer.fullName}`, /*Description*/ `If you delete ${customer.fullName} you will lose all information associated with it.`])
      setShowModal(true)
      setConfirmDelete(() => confirmDeleteCustomer)
    } else if (type === "note") {
      setWarningModalInfo([/*Title*/ ` ${note.createdBy}'s Note`, /*Description*/ `If you delete this note you will not be able to recover it.`])
      // setCurrentNote(note)
      setConfirmDelete(() => () => confirmDeleteNote(note))
      setShowModal(true)
    }
  }

  function confirmDeleteCustomer() {
    setShowModal(false)
    deleteCustomer(customer.id).then(() => {
      refreshCallback()
    })
  }

  function confirmDeleteNote(currentNote) {
    setShowModal(false)

    deleteNote(customer.id, currentNote.id)
      .then(() => {
        setNotes((prev) => {
          const newNotes = [...prev]
          const noteIndex = newNotes.findIndex((note) => note.id === currentNote.id)
          newNotes.splice(noteIndex, 1)
          return newNotes
        })
        // refreshCallback()
      })
      .catch((err) => console.log(err))
  }

  const handleShowInfoModal = (name, image, description, link) => {
    setShowInfoModal(true)
    setModalInfo([name, image, description, link])
  }

  function handleCloseInfoModal() {
    setShowInfoModal(false)
    setModalInfo([])
  }

  function closeEditNote(index) {
    notes[index].showSave = false
    setNotes([...notes])
  }

  function handleEditNote(note, index) {
    setEditingNoteId(note.id)
    setNotes((prev) => {
      const newNotes = [...prev]
      newNotes[index].showSave = true
      return newNotes
    })
  }

  function handleUpdateNote(e, note) {
    const editedNoteText = e.target.previousSibling.value
    const noteId = note.id
    if (editedNoteText === "") {
      setNotes((prev) => {
        const newNotes = [...prev]
        const noteIndex = newNotes.findIndex((note) => note.id === noteId)
        newNotes[noteIndex].showSave = false
        return newNotes
      })
      return
    }

    const createdAt = Date.now()
    const createdBy = `${firstName[0] + " " + lastName}`
    const editedNote = { id: noteId, note: editedNoteText, createdAt, createdBy }
    editNote(customer.id, editedNote)
      .then(() => {
        setNotes((prev) => {
          const newNotes = [...prev]
          const noteIndex = newNotes.findIndex((note) => note.id === noteId)
          newNotes[noteIndex] = { ...newNotes[noteIndex], ...editedNote, showSave: false }
          return newNotes
        })
      })
      .catch((err) => console.log(err))
  }

  function handleSubmitNote(e) {
    e.preventDefault()
    if (textareaRef.current) {
      textareaRef.current.blur()
      const note = textareaRef.current.value
      textareaRef.current.value = ""
      const createdAt = Date.now()
      const noteId = uuidv4()
      const newNote = { note: note, createdBy: `${firstName[0] + " " + lastName}`, createdAt, id: noteId }
      createNote(customer.id, newNote)
        .then(() => {
          setNotes([newNote, ...notes])
        })
        .catch((err) => console.log(err))
    }
  }

  function handleFocus(e) {
    const noteDiv = e.currentTarget.closest(".note")
    const textArea = e.currentTarget
    if (noteDiv) {
      noteDiv.classList.add("note-highlighted")
      // set height based on scroll height
      textArea.style.height = "auto"
      textArea.style.height = textArea.scrollHeight + "px"
    }
  }

  function handlePrimaryNote(e) {
    e.preventDefault()
    setPrimaryNote(e.target.previousSibling.value)
    setEditPrimaryNote(false)
    editDbPrimaryNote(customer.id, e.target.previousSibling.value)
  }

  function handleBlur(e) {
    const noteDiv = e.currentTarget.closest(".note")
    if (noteDiv) {
      noteDiv.classList.remove("note-highlighted")
    }
  }

  return (
    <div className="customer-info-container" key={i}>
      <div>{customer.photo}</div>
      {userRole && userRole === "admin" && (
        <div>
          <span className="medium-font delete-customer-icon" onClick={() => handleDelete("customer")}>
            <RiDeleteBin2Line className="text-danger" />
          </span>
        </div>
      )}
      <div>
        <a href={`tel:${customer.mobile}`}>{customer.mobile}</a>
      </div>
      <a href={`mailto:${customer.email}?subject=Yard To Fork&body=Hi ${customer.firstName},`}>{customer.email}</a>
      {customer.address && (
        <div>
          <a href={`http://google.com/maps/search/?api=1&query=${customer.address}/${customer.city}/${customer.state}`}>{customer.address + ", " + customer.city + ", " + customer.state}</a>
        </div>
      )}
      {editPrimaryNote ? (
        <form className="d-flex">
          <textarea className="note-area" name="primary-note" defaultValue={primaryNote} />

          <button className="btn-note" onClick={handlePrimaryNote}>
            Save
          </button>
        </form>
      ) : (
        <div className="d-flex align-items-center">
          <div className="editable-note d-flex align-items-center">{primaryNote}</div>
          <span
            className="medium-font p-3"
            onClick={() => {
              setEditPrimaryNote(true)
            }}
          >
            <AiOutlineEdit />
          </span>
        </div>
      )}
      <div>
        <Accordion>
          <Accordion.Item eventKey={i} key={i + 1000}>
            <Accordion.Header bsPrefix="custom-plants-accordion" id="plants-header">
              Plants
            </Accordion.Header>
            <Accordion.Body>
              {sortBy(customer.plants, "category").map((plant, i) => {
                let displayCategory = null
                if (!catSet.has(plant.category)) {
                  displayCategory = plant.category
                  catSet.add(displayCategory)
                }
                return (
                  <div key={plant.id}>
                    <h3>
                      <strong>{displayCategory}</strong>
                    </h3>
                    <div key={i + plant.name} className="d-flex align-items-center">
                      <FormCheck className="customCheckbox" label={plant.name} name={plant.name}></FormCheck>
                      <span onClick={() => handleShowInfoModal(plant.name, plant.imgUrl, plant.description, plant.link)}>
                        <div className="photo-icon">
                          <AiOutlineCamera />
                        </div>
                      </span>
                    </div>
                  </div>
                )
              })}
              {customer.specialtyRequests && (
                <>
                  <strong>Specialty Requests</strong>
                  <ul>{customer.specialtyRequests}</ul>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey={i + 1001}>
            <Accordion.Header bsPrefix="custom-plants-accordion" id="plants-header">
              Messages
            </Accordion.Header>
            <Accordion.Body>
              {customer?.messages.map((message, i) => {
                return (
                  <div key={i + message}>
                    <p >{message.message}</p>
                    <span>{message.timeStamp}</span>
                  </div>
                )
              })}
            </Accordion.Body>
          </Accordion.Item> */}
          <Accordion.Item eventKey={i + 1002} key={i + 1002}>
            <Accordion.Header bsPrefix="custom-plants-accordion" id="plants-header">
              Notes
            </Accordion.Header>
            <Accordion.Body>
              <form className="d-flex">
                <textarea ref={textareaRef} className="note-area" name="note-input" />
                <button className="btn-note" onClick={handleSubmitNote}>
                  Save
                </button>
              </form>
              {notes?.map((note, i) => {
                return (
                  <div key={note.id} className="note" tabIndex="0" onBlur={handleBlur}>
                    <div className="note-header d-flex justify-content-between w-100">
                      <p>{note.createdBy}</p>
                      <p>{new Date(note.createdAt).toLocaleString()}</p>
                    </div>
                    <div
                      className="d-flex"
                      onBlur={(e) => {
                        if (e.relatedTarget?.textContent === "Save" || e.relatedTarget.textContent.includes("Save")) {
                          return
                        } else if (note.showSave) {
                          closeEditNote(i)
                        }
                      }}
                    >
                      {note.showSave ? (
                        <>
                          <textarea className="editable-note d-flex" ref={noteRefs.current[i]} defaultValue={note.note} onFocus={handleFocus} />
                          <button
                            className="btn-note justify-self-end"
                            id={note.id}
                            onClick={(e) => {
                              return handleUpdateNote(e, note)
                            }}
                          >
                            Save
                          </button>
                        </>
                      ) : (
                        <>
                          <div className="editable-note d-flex">{note.note}</div>
                          <span
                            className="medium-font px-5"
                            onClick={(e) => {
                              notes[i].showSave = true
                              setNotes([...notes])
                              handleEditNote(note, i)
                            }}
                          >
                            <AiOutlineEdit />
                          </span>
                          <div>
                            <span className="medium-font" onClick={() => handleDelete("note", note)}>
                              <RiDeleteBin2Line className="text-danger" />
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )
              })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <WarningModal show={showModal} close={() => setShowModal(false)} confirmDelete={confirmDelete} modalInfo={warningModalInfo} />
      <InfoModal show={showInfoModal} close={handleCloseInfoModal} modalInfo={modalInfo} />
    </div>
  )
}
