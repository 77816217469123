import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey:  process.env.REACT_APP_PUBLIC_FIREBASE_API_KEY,
    authDomain: "yard-to-fork-2585e.firebaseapp.com",
    projectId: process.env.REACT_APP_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: "yard-to-fork-2585e.appspot.com",
    messagingSenderId: "774685207583",
    appId: "1:774685207583:web:099f91f278798cfea6392a",
    measurementId: "G-E0HQVHBP0L",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
// const analytics = getAnalytics(app);
