import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from "firebase/auth"
import { auth } from "../Config/firebaseConfig"

export async function login(email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    const user = userCredential.user
    return user
  } catch (error) {
    // const errorCode = error.code;
    // const errorMessage = error.message;
    throw error
  }
}

export async function createAccount(email, password) {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password)
    const user = userCredential.user
    return user
  } catch (error) {
    console.error(error.code)
    if(error.code === 'auth/email-already-in-use'){
      alert("Email already in use.")
    }
    
  }
}

export function logout() {
  const auth = getAuth()
  signOut(auth)
    .then(() => {
      window.location.reload()
    })
    .catch((error) => {
      console.log(error.message)
    })
}

export async function resetFirebasePassword(email) {
  const auth = getAuth()
  sendPasswordResetEmail(auth, email)
    .then(() => {
      return "success"
    })
    .catch((error) => {
      alert(error)
    })
}
