import React from "react"
import "./Footer.css"
import { useLocation, useNavigate } from "react-router-dom"
import BackArrow from "./BackArrow"

export default function Navigation() {
  const navigate = useNavigate()
  const location = useLocation()
  const inTutorial = location.pathname.includes("initial")
  return (
    <div className="footer d-flex justify-content-start align-items-center" hidden={inTutorial}>
      <div onClick={() => navigate(-1)}>
        <BackArrow />
      </div>
    </div>
  )
}
