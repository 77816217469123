import React from "react";
import "./Footer.css";
import { BiArrowBack } from "react-icons/bi";
import { useLocation } from "react-router-dom";

export default function BackArrow() {
    const location = useLocation();

    const backArrow = location.pathname !== "/" && !location.pathname.includes("initial")

    return (
        <>
            {backArrow && (
                <div className="text-white large-font m-4">
                    <BiArrowBack />
                </div>
            )}
        </>
    );
}
