import React from "react"
import { Form } from "react-bootstrap"
import { useLocation } from "react-router-dom"
import { updatePlantsInfo } from "../../../../firestore/firestore"
import { formatGoogleDriveImage } from "../../../../util/helper"

export default function AddPlant() {
  const location = useLocation()
  const { categories, plantsData } = location.state

  function handleFormSubmit(e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    const newPlant = {
      active: true,
      category: formData.get("category"),
      description: formData.get("plantDescription"),
      imgUrl: formatGoogleDriveImage(formData.get("plantImgUrl")),
      link: formData.get("plantLink"),
      name: formData.get("plantName"),
      plantingMonths: [Number(formData.get("seasonStart")), Number(formData.get("seasonEnd"))],
      size: Number(formData.get("plantSize")),
    }

    plantsData.push(newPlant)
    updatePlantsInfo(plantsData)
    e.target.reset()
  }

  return (
    <div className="text-start mt-4">
      <h1>Add New Plant</h1>
      <Form onSubmit={handleFormSubmit}>
        <Form.Group className="my-3" controlId="plantCategory">
          <Form.Label>Category</Form.Label>
          <Form.Select
            aria-label="Default select example"
            // id="plant-category"
            className="custom-select"
            name="category"
          >
            {categories.map((cat, i) => {
              return (
                <option key={i + cat} value={cat}>
                  {cat}
                </option>
              )
            })}
          </Form.Select>
        </Form.Group>

        <Form.Group className="my-3" controlId="plantName">
          <Form.Label>Plant Name</Form.Label>
          <Form.Control
            // id="plantName"
            name="plantName"
            required
          />
        </Form.Group>

        <Form.Group className="my-3" controlId="plantSize">
          <Form.Label>Plant Size</Form.Label>
          <aside className="plant-aside">Number of square inches the plant needs.</aside>
          <Form.Control
            // id="plantSize"
            name="plantSize"
            placeholder="10"
            type="number"
            defaultValue={10}
            min={1}
            max={10000}
            step={1}
          />
        </Form.Group>
        <Form.Group className="my-3" controlId="plantingSeason">
          <Form.Label>Growing Season</Form.Label>
          <div className="d-flex justify-content-between">
            <div>
              <aside className="plant-aside">Starting Month</aside>
              <Form.Select name="seasonStart" placeholder="1" type="select" defaultValue={0}>
                <option value={0}>Jan</option>
                <option value={1}>Feb</option>
                <option value={2}>Mar</option>
                <option value={3}>Apr</option>
                <option value={4}>May</option>
                <option value={5}>Jun</option>
                <option value={6}>Jul</option>
                <option value={7}>Aug</option>
                <option value={8}>Sep</option>
                <option value={9}>Oct</option>
                <option value={10}>Nov</option>
                <option value={11}>Dec</option>
              </Form.Select>
            </div>
            <div>
              <aside className="plant-aside">Ending Month</aside>
              <Form.Select name="seasonEnd" placeholder="1" type="select" defaultValue={11}>
                <option value={0}>Jan</option>
                <option value={1}>Feb</option>
                <option value={2}>Mar</option>
                <option value={3}>Apr</option>
                <option value={4}>May</option>
                <option value={5}>Jun</option>
                <option value={6}>Jul</option>
                <option value={7}>Aug</option>
                <option value={8}>Sep</option>
                <option value={9}>Oct</option>
                <option value={10}>Nov</option>
                <option value={11}>Dec</option>
              </Form.Select>
            </div>
          </div>
        </Form.Group>
        <Form.Group className="my-3" controlId="plantImage">
          <Form.Label>Image Url</Form.Label>
          <aside className="plant-aside">Get image address not image link address.</aside>
          <Form.Control name="plantImgUrl" placeholder="http://exampleplantimage.com" defaultValue="" />
        </Form.Group>
        <Form.Group className="my-3" controlId="plantDescription">
          <Form.Label>Description</Form.Label>
          <aside className="plant-aside">Short description of the plant.</aside>
          <Form.Control name="plantDescription" as="textarea" />
        </Form.Group>
        <Form.Group className="my-3" controlId="plantLink">
          <Form.Label>Link</Form.Label>
          <aside className="plant-aside">Add an optional link for more info on the plant.</aside>
          <Form.Control name="plantLink" type="url" />
        </Form.Group>
        <button className="btn-add mx-auto" type="submit">
          Save
        </button>
      </Form>
    </div>
  )
}
