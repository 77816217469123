import React from "react";
import { FormControl, FormGroup } from "react-bootstrap";

/**
 *
 *InputGroup function
 * @param {Array} inputs: Array of objects. Each object represents an input with a label, type, value, and handler i.e., [{label:"Name", type:text, value:name, handler:handleName}]
 * @param {function} submit: submit form handler.
 */
export default function InputGenerator({
    inputs, //Array of objects that include labels and inputs.
    handleChange,
}) {
    return (
        <>
            <FormGroup>
                {inputs.map((input, i) => {
                    return (
                        <React.Fragment key={input.label + i}>
                            <label htmlFor={input.label + i}>
                                {input.label}
                            </label>
                            <FormControl
                                type={input.type}
                                name={input.label.toLowerCase()}
                                id={input.label + i}
                                value={input.value}
                                required
                                onChange={handleChange}
                                pattern={input.pattern}
                                placeholder={input.placeholder}
                            />
                        </React.Fragment>
                    );
                })}
            </FormGroup>
            {/* <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                {inputs.map((input, i) => {
                    return (
                        <React.Fragment key={input.label + i}>
                            <TextField
                                variant="filled"
                                type={input.type}
                                label={input.label}
                                name={input.label.toLowerCase()}
                                id={input.label + i}
                                value={input.value}
                                required
                                onChange={handleChange}
                                pattern={input.pattern}
                                placeholder={input.placeholder}
                            />
                        </React.Fragment>
                    );
                })}
            </Box> */}
        </>
    );
}
