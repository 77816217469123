import React, { useEffect, useRef, useState } from "react"
import { Accordion } from "react-bootstrap"
import CustomerInfo from "../../../../components/CustomerInfo/customerInfo"
import LoadingSpinner from "../../../../components/Loading/LoadingSpinner"
import CustomerAccordion from "../../../../components/Selection/CustomerAccordion"
import { getAllCustomerInfo } from "../../../../firestore/firestore"
import PullToRefresh from "react-simple-pull-to-refresh"
import { sortArrayOfObjects } from "../../../../util/helper"

export default function Customers() {
  const [customers, setCustomers] = useState("")
  const [currentTarget, setCurrentTarget] = useState(null)
  const accordionRef = useRef()

  useEffect(() => {
    handleRefresh()
  }, [])

  useEffect(() => {
    if (!currentTarget) {
      return
    }
    accordionRef.current = currentTarget

    setTimeout(
      () =>
        accordionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      200
    )
  }, [currentTarget])

  async function handleRefresh() {
    const getCustomerData = async () => {
      const customerData = await getAllCustomerInfo()
      const sortedCustomerData = customerData.sort((a, b) => sortArrayOfObjects(a.lastName, b.lastName))
      setCustomers([])
      // setCustomers(sortedCustomerData)
      //force page to refresh even if data is the same
      return sortedCustomerData
    }
    getCustomerData().then((results) => setCustomers(results)).catch((err) => console.log(err))
  }

  function handleAccordion(e) {
    const element = e.currentTarget
    setCurrentTarget((prevTarget) => (prevTarget = element))
  }
  if (customers) {
    return (
      <>
        <PullToRefresh onRefresh={handleRefresh} pullDownThreshold={90} resistance={3}>
          <Accordion className="custom-accordion">
            {customers.map((customer, i) => {
              return (
                <div key={i + customer.id} ref={accordionRef} onClick={handleAccordion}>
                  {/* Create accordian item with plant offerings. */}
                  <CustomerAccordion eventKey={i} customer={customer} id={i + customer.id}>
                    <CustomerInfo customer={customer} i={i} refreshCallback={handleRefresh} />
                  </CustomerAccordion>
                </div>
              )
            })}
          </Accordion>
        </PullToRefresh>
      </>
    )
  } else {
    return <LoadingSpinner />
  }
}
