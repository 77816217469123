import { useState } from "react"
import { Link, Route, Routes, useNavigate } from "react-router-dom"
import mainLogo from "../../../assets/main-logo.png"

import { createUser } from "../../../firestore/firestore"
import { createAccount } from "../../../auth/auth"
import "../SignInPage.css"

import SignUpPage1 from "./SignUpPage1"
import SignUpPage2 from "./SignUpPage2"
import SignUpPage3 from "./SignUpPage3"
import { useDispatch } from "react-redux"
import { setActiveUser } from "../../../features/user/userSlice"
import { loginLinkStyle } from "../../../styles"
import BackArrow from "../../../components/Footer/BackArrow"

function SignUpPage({ setUserData }) {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("CA")
  const [zipcode, setZipcode] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  function signUp() {
    if (password !== confirmPassword) {
      setPassword("")
      setConfirmPassword("")
      alert("Passwords do not match.")
      return
    }
    const addUserAndAddtoDb = async () => {
      const user = await createAccount(email, password)

      const userData = {
        id: user.uid,
        firstName: user.displayName ? user.displayName.split(" ")[0] : firstName,
        lastName: user.displayName ? user.displayName.split(" ")[1] : lastName,
        fullName: user.displayName || firstName + " " + lastName,
        email: user.email,
        role: user.email === "sean.j.finegan@gmail.com" ? "admin" : "customer",
        imgUrl: "",
        mobile: mobile,
        address: address,
        city: city,
        state: state,
        zipcode: zipcode,
        plants: [],
        messages: [],
        active: true,
      }
      dispatch(setActiveUser(userData))
      createUser(userData) // Add user to firestore db
      setUserData(userData)
      navigate("/")
    }

    addUserAndAddtoDb()
    resetForm()
  }

  function resetForm() {
    setFirstName("")
    setLastName("")
    setEmail("")
    setMobile("")
    setAddress("")
    setCity("")
    setState("CA")
    setZipcode("")
    setPassword("")
    setConfirmPassword("")
  }

  function handleChange(e) {
    const name = e.target.name
    const value = e.target.value

    switch (name) {
      case "first name":
        setFirstName(value)
        break
      case "last name":
        setLastName(value)
        break
      case "email":
        setEmail(value)
        break
      case "mobile":
        setMobile(value)
        break
      case "address":
        setAddress(value)
        break
      case "city":
        setCity(value)
        break
      case "state":
        setState(value)
        break
      case "zipcode":
        setZipcode(value)
        break
      case "password":
        setPassword(value)
        break
      case "confirm":
        setConfirmPassword(value)
        break
      default:
        break
    }
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 sign-in-background">
      <h4 className="mt-4 text-white">Registration</h4>
      <div className=" d-flex flex-column justify-content-center align-items-center sign-in">
        <img src={mainLogo} alt="Main logo" className="w-50 m-2" />
        <Routes>
          <Route path="/" element={<SignUpPage1 handleChange={handleChange} email={email} password={password} confirmPassword={confirmPassword} />} />
          <Route path="/sign-in2" element={<SignUpPage2 handleChange={handleChange} firstName={firstName} lastName={lastName} mobile={mobile} />} />
          <Route path="/sign-in3" element={<SignUpPage3 handleChange={handleChange} signUp={signUp} address={address} city={city} state={state} zipcode={zipcode} />} />
        </Routes>
      </div>
      
      <footer className="sign-in-footer">
        <div onClick={() => navigate(-1)} className="back-arrow-footer">
          <BackArrow />
        </div>
        <div className="login-footer">
        <Link to="/" style={loginLinkStyle}>
          Login
        </Link>
        </div>
      </footer>
    </div>
  )
}

export default SignUpPage
