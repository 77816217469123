import { useEffect, useState } from "react";
import { celsiusToFarenheit, fetchWeather } from "../../util/helper";
import WeatherIcon from "./WeatherIcon";
import "./WeatherComponent.css"

export default function WeatherComponent() {
    const [currentWeather, setCurrentWeather] = useState();
    const [forecast, setForecast] = useState();
    useEffect(() => {
        fetchWeather().then((data) => {
          setCurrentWeather(data.currentConditions)
          setForecast(data.days)
        })
    }, []);
   
    return (
      <>
        {currentWeather && forecast ? (
          <div className="d-flex flex-column justify-content-center align-items-center mt-4 ">
            <h2 className="mb-0 mx-2 d-flex align-items-center weather-description">
              <WeatherIcon iconTag={forecast[0]?.icon} />
              Today&nbsp;
              <span>{celsiusToFarenheit(forecast[0].tempmax) + "/" + celsiusToFarenheit(forecast[0].tempmin) + "°F"}</span>
            </h2>

            <h2 className="mb-0 mx-2 d-flex align-items-center weather-description">
              <WeatherIcon iconTag={forecast[1]?.icon} />
              Tomorrow&nbsp;
              <span>{celsiusToFarenheit(forecast[1].tempmax) + "/" + celsiusToFarenheit(forecast[1].tempmin) + "°F"}</span>
            </h2>
          </div>
        ) : null}
      </>
    )
}
