import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { MdOutlineIosShare } from "react-icons/md"
import { BsThreeDotsVertical } from "react-icons/bs"
import { CgAddR } from "react-icons/cg"
import { FormSelect } from "react-bootstrap"
import { useState } from "react"
import YtFIcon from "../../../assets/ytf-colored-apple-logo.png"
import { useEffect } from "react"
import { getPWADisplayMode } from "../../../util/helper"
import ytfLogo from "../../../assets/ytf-colored-apple-logo.png"
import "./InitialPage.css"

export default function InitialIntroPage() {
  const [device, setDevice] = useState(null)
  const [displayMode, setDisplayMode] = useState("")
  const navigate = useNavigate()

  useEffect(() => {
    setDisplayMode(getPWADisplayMode())
    if (displayMode === "standalone") {
      navigate("/")
    }
  }, [displayMode, navigate])

  function handleSelect(e) {
    const device = e.target.value
    setDevice(device)
  }

  return (
    <div className="initial-container p-4 mt-3">
      <div className="d-flex justify-content-center">
        <img src={ytfLogo} alt="ytf-logo" />
      </div>
      <h1 className="text-center initial-title">Welcome to Yard to Fork!</h1>
      <main>
        {!displayMode || displayMode === "browser" ? (
          <section>
            <p>The Yard to Fork app was designed to allow our customers to easily choose or update their plant preferences each growing season. You will also have early access to our quarterly newsletter.</p>
            <p>Since the app is designed primarly for phones and tablets, follow the steps below to add it to your device.</p>

            <FormSelect aria-label="Select device" onChange={handleSelect} id="select-device">
              <option value={""}>Choose your device</option>
              <option value={"apple"}>iPhone / iPad</option>
              <option value={"android"}>Android Phone / Tablet</option>
              <option value={"computer"}>Desktop / Laptop</option>
            </FormSelect>
            {device === "apple" && (
              <div className="instructions">
                <ol>
                  <li>Make sure this app is open in Safari</li>
                  <li>
                    Tap on share <MdOutlineIosShare style={{ fontSize: 25 }} />
                  </li>
                  <li>
                    Scroll down to <strong>Add to Home Screen</strong> <CgAddR style={{ fontSize: 25 }} />
                  </li>
                  <li>
                    Close Safari and tap on{" "}
                    <span className="ytf-icon mx-1">
                      <img src={YtFIcon} alt="ytf Icon" />
                    </span>
                  </li>
                </ol>
              </div>
            )}
            {device === "computer" && (
              <div className="instructions">
                <p>Great! You don't need to do anything. The display may seem scarce on a big screen since it was designed for smaller screens but it will function the same. You can also use the Yard to Fork app from multiple devices once you have an account.</p>

                <p>Lets get started.</p>
                <Link to={"/"} className="d-flex justify-content-center pt-4 text-decoration-none">
                  <button className="btn-add">Next</button>
                </Link>
              </div>
            )}
            {device === "android" && (
              <div className="instructions">
                <ol>
                  <li>Make sure you are in Chrome</li>
                  <li>
                    Tap menu <BsThreeDotsVertical style={{ fontSize: 25 }} /> at the top right
                  </li>
                  <li>
                    Tap <strong>Add to Home Screen</strong>
                  </li>
                  <li>
                    Drag{" "}
                    <span className="ytf-icon">
                      {" "}<img src={YtFIcon} alt="Yard to Fork Icon" />{" "}
                    </span>
                    to home screen
                  </li>
                </ol>
              </div>
            )}
            <br />
          </section>
        ) : (
          <section>
            <p>Let's get started.</p>
            <Link to={"/main/initialFamily"} className="d-flex justify-content-center pt-4">
              <button className="btn-add">Next</button>
            </Link>
          </section>
        )}
      </main>
    </div>
  )
}
