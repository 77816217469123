import { ReactComponent as ClearDay } from "../../assets/clear-day.svg";
import { ReactComponent as ClearNight } from "../../assets/clear-night.svg";
import { ReactComponent as Cloudy } from "../../assets/cloudy.svg";
import { ReactComponent as Fog } from "../../assets/fog.svg";
import { ReactComponent as Hail } from "../../assets/hail.svg";
import { ReactComponent as PartlyCloudyDay } from "../../assets/partly-cloudy-day.svg";
import { ReactComponent as PartlyCloudyNight } from "../../assets/partly-cloudy-night.svg";
import { ReactComponent as RainSnowShowersDay } from "../../assets/rain-snow-showers-day.svg";
import { ReactComponent as RainSnowShowersNight } from "../../assets/rain-snow-showers-night.svg";
import { ReactComponent as Rain } from "../../assets/rain.svg";
import { ReactComponent as ShowersDay } from "../../assets/showers-day.svg";
import { ReactComponent as ShowersNight } from "../../assets/showers-night.svg";
import { ReactComponent as ThunderRain } from "../../assets/thunder-rain.svg";
import { ReactComponent as ThunderShowersDay } from "../../assets/thunder-showers-day.svg";
import { ReactComponent as ThunderShowersNight } from "../../assets/thunder-showers-night.svg";
import { ReactComponent as Wind } from "../../assets/wind.svg";

export default function WeatherIcon({ iconTag }) {
    const icon = {
        "clear-day": <ClearDay />,
        "clear-night": <ClearNight />,
        cloudy: <Cloudy />,
        fog: <Fog />,
        hail: <Hail />,
        "partly-cloudy-day": <PartlyCloudyDay />,
        "partly-cloudy-night": <PartlyCloudyNight />,
        "rain-snow-showers-night": <RainSnowShowersNight />,
        "rain-snow-showers-day": <RainSnowShowersDay />,
        rain: <Rain />,
        "showers-day": <ShowersDay />,
        "showers-night": <ShowersNight />,
        "thunder-rain": <ThunderRain />,
        "thunder-showers-day": <ThunderShowersDay />,
        "thunder-showers-night": <ThunderShowersNight />,
        wind: <Wind />,
    };

    return (
        <div className="mx-2" style={{ width: 25 }}>
            {icon[iconTag]}
        </div>
    );
}
