import { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import mainLogo from "../../assets/main-logo.png"
import LoadingSpinner from "../Loading/LoadingSpinner"
import "./Modal.css"

/**
 * @function InfoModal
 * @param {close} close: Boolean
 * @param {show} show: Boolean
 * @param {modalInfo} modalInfo: Array[title, image, description]
 * @returns modal that is centered on the screen
 */
export default function InfoModal({ close, show, modalInfo }) {
  const [isLoading, setIsLoading] = useState(true)

  // destructure modal info from array
  let [title, image, description, link] = modalInfo

  // If the image is stored in google drive, format the image.
  if (image && image.includes("drive.google.com")) {
    let imageId = image.split("/")[5] // gets the image id from the url
    image = `https://drive.google.com/thumbnail?id=${imageId}`
  }

  function handleImageLoaded(e) {
    setIsLoading(false)
  }
  function handleClose() {
    setIsLoading(true)
    close()
  }

  return (
    <>
      <div className="center">
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header className="custom-modal-header" closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoading && <LoadingSpinner />}
            <div className="InfoModalImage">
              <img onLoad={handleImageLoaded} className="InfoModalImage img-fluid" src={image || mainLogo} crossOrigin="true" alt="plant" loading="eager" />
            </div>
            <hr />

            {description ? description + " " : "Description coming soon!"}
            {link ? <a href={link}>More Info</a> : null}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose} className="btn-lg">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}
