import InputGenerator from "../../../components/Login/InputGenerator";
import "../SignInPage.css";

function SignUpPage3({ handleChange, signUp, address, city, state, zipcode }) {
    
    function validate() {
        let isNum = /^\d+$/.test(address[0]);
        let includesLetters = /[a-zA-Z]/g.test(address);
        if (!address || !city || !state) {
            return alert("Please fill out all fields.");
        } else if (!isNum) {
            return alert("Please enter a valid address.");
        } else if (!includesLetters) {
            return alert("Please enter valid address2.");
        } else {
            signUp();
        }
    }
    return (
        <>
            <InputGenerator
                inputs={[
                    {
                        label: "Address",
                        type: "address",
                        value: address,
                    },
                    {
                        label: "City",
                        type: "city",
                        value: city,
                    },

                    {
                        label: "State",
                        type: "state",
                        value: state,
                        placeholder: "CA",
                    },
                    {
                        label: "Zipcode",
                        type: "zipcode",
                        value: zipcode,
                    },
                ]}
                handleChange={handleChange}
            />

            <button className="sign-in-button" onClick={validate}>
                Sign Up
            </button>
        </>
    );
}

export default SignUpPage3;
