import { Accordion } from "react-bootstrap"

export default function CategoryButton({ eventKey, category, id, children }) {
  return (
    <Accordion.Item eventKey={eventKey} id={id}>
      <Accordion.Header>{category}</Accordion.Header>
      <Accordion.Body>{children}</Accordion.Body>
    </Accordion.Item>
  )
}
