import { useNavigate } from "react-router-dom";
import InputGenerator from "../../../components/Login/InputGenerator";
import "../SignInPage.css";

function SignUpPage2({ handleChange, firstName, lastName, mobile }) {
    const navigate = useNavigate();
    function validate() {
        let isnum = /^\d+$/.test(mobile);
        if (!firstName || !lastName) {
            return alert("Please enter your first and last name.");
        } else if (!mobile) {
            return alert("Please enter a valid phone number");
        } else if (!isnum) {
            return alert("Please enter numbers only for your phone number.");
        } else if (mobile.length !== 10) {
            return alert("Please enter your 10 digit phone number.");
        } else {
            navigate("/sign-up/sign-in3");
        }
    }
    return (
        <>
            <InputGenerator
                inputs={[
                    {
                        label: "First Name",
                        type: "firstName",
                        value: firstName,
                    },
                    {
                        label: "Last Name",
                        type: "lastName",
                        value: lastName,
                    },
                    {
                        label: "Mobile",
                        type: "tel",
                        value: mobile,
                        placeholder: "5551235000",
                    },
                ]}
                handleChange={handleChange}
            />

            <button className="sign-in-button" onClick={validate}>
                Next
            </button>
        </>
    );
}

export default SignUpPage2;
