import { Accordion } from "react-bootstrap";

export default function CustomerAccordion({
    eventKey,
    id,
    customer,
    children,
}) {
    return (
        <Accordion.Item
            eventKey={eventKey}
            className="custom-accordion-item"
            id={id}
            key={id+customer}
        >
            <Accordion.Header className="custom-accordion-header">
                {customer.lastName}, {customer.firstName}
            </Accordion.Header>
            <Accordion.Body>{children}</Accordion.Body>
        </Accordion.Item>
    );
}
