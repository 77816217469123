import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    firstName: "",
    lastName: "",
    fullName: "",
    email: null,
    mobile: null,
    imgUrl: null,
    id: null,
    plants: [],
    specialtyRequests: "",
    // messages: [],
    role: "customer",
    active: true,
    address: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setActiveUser: (state, action) => {
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
      state.fullName = action.payload.fullName
      state.email = action.payload.email
      state.id = action.payload.id
      state.imgUrl = action.payload.imgUrl
      state.plants = action.payload.plants
      state.specialtyRequests = action.payload.specialtyRequests
      // state.messages = action.payload.messages
      // state.notes = action.payload.notes
      state.role = action.payload.role
      state.mobile = action.payload.mobile
      state.active = action.payload.active
      state.address = action.payload.address
      state.city = action.payload.city
      state.state = action.payload.state
    },
    setUserLogout: (state) => {
      state = null
    },
    setPlants: (state, action) => {
      state.plants = action.payload.plants //Array: ["", "",...]
    },

    setSpecialtyRequests: (state, action) => {
      state.specialtyRequests = action.payload.specialtyRequests
    },

    // setNotes: (state, action) => {
    //   state.notes = action.payload.notes // Array of Objects: [{note:"", timeStamp:""}]
    // },

    addPlant: (state, action) => {
      state.plants = [...state.plants, action.payload.plant]
    },

    deletePlant: (state, action) => {
      state.plants = state.plants.filter((plant) => plant.name !== action.payload.plant)
    },

    // setMessage: (state, action) => {
    //   state.messages = action.payload.messages //Array of Objects:[{message:"", timeStamp:""}]
    // },
    setRole: (state, action) => {
      state.role = action.payload.role //String: admin, employee, customer
    },
    
  },
})

export const { setActiveUser, setUserLogout, setPlants, setSpecialtyRequests, addPlants, deletePlants, setRole } = userSlice.actions

export const selectActiveUser = (state) => state?.user;
export const selectUserLastName = (state) => state.user.lastName;
export const selectUserFirstName = (state) => state.user.firstName;
export const selectUserRole = (state) => state.user.role;
export const selectUserEmail = (state) => state.user.email;
export const selectUserId = (state) => state.user.id;
export const selectUserImage = (state) => state.user.imgUrl;
export const selectPlants = (state) => state.user.plants;
export const selectSpecialtyRequests = (state) => state.user.specialtyRequests;
// export const selectNotes = (state) => state.user.notes;
// export const selectMessages = (state) => state.user.messages;
export const selectRole = (state) => state.user.role;

export default userSlice.reducer;
