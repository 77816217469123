import { timeOfDayGreeting as greeting } from "../../../util/helper"
import WeatherComponent from "../../../components/Weather/WeatherComponent"
import "./Home.css"
import MainLogo from "../../../components/Images/MainLogo"
import HomeButtonsContainer from "../../../components/Containers/HomeButtonsContainer"

export default function Home({ user }) {
  return (
    <div className="d-flex flex-column flex-grow-1 home-container">
      <div className="d-flex justify-content-center">
        <div className="main-logo-container">
          <MainLogo />
        </div>
      </div>
      <div className="home-spacer">
        <h1 className="home-user-title">{greeting() + " " + user.firstName + "!"}</h1>
        <WeatherComponent />
      </div>
      <HomeButtonsContainer user={user}/>
    </div>
  )
}

// function HomeButtonsContainer() {
//   return (
//     <div className="home-buttons-container">
//       <div className="d-flex justify-content-center px-3">
//         <HomeButton to={"/preferences"} buttonName="My Plants" />
//         <HomeButton to={"/info"} buttonName="Info" />
//       </div>
//       <div className="d-flex justify-content-center flex-wrap px-3 pb-2 mb-1">
//         <HomeButton to={"/gallery"} buttonName="Gallery" />
//         <HomeButton to={`/contact`} buttonName="Contact" />
//       </div>
//     </div>
//   )
// }
