import { Button, Modal } from "react-bootstrap";
import "./Modal.css";

/**
 * @function WarningModal
 * @param {close} close: Boolean
 * @param {show} show: Boolean
 * @param {confirmDelete } confirmDelete: function
 * @param {modalInfo} modalInfo: Array[title, description]
 * @returns modal that is centered on the screen
 */
export default function WarningModal({
    close,
    show,
    confirmDelete,
    modalInfo,
}) {
    const [title, description] = modalInfo;
    return (
        <>
            <div className="center">
                <Modal
                    show={show}
                    onHide={close}
                    centered
                    className="warning-modal"
                >
                    <Modal.Header className="custom-modal-header" closeButton>
                        <Modal.Title variant="warning">
                            <span className="large-font text-danger">
                                Delete
                            </span>
                            {title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{description + " "}</Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={close}
                            className="btn-lg"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="danger"
                            onClick={confirmDelete}
                            className="btn-lg"
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}
