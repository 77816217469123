export function timeOfDayGreeting() {
  const time = new Date().toTimeString().slice(0, 2)
  let greeting = "Welcome"
  if (time < 12) {
    greeting = "Good morning"
  } else if (time < 18) {
    greeting = "Good afternoon"
  } else {
    greeting = "Good evening"
  }
  return greeting
}

export function fetchWeather(userZipcode = 95655) {
  return fetch(`https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${userZipcode}?unitGroup=metric&include=days%2Ccurrent&key=8CXB2HVSAP5UGF94AE7ETLGB3&contentType=json`, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      return data
    })
    .catch((err) => {
      console.error(err)
    })
}

export function celsiusToFarenheit(celsiusNumber) {
  const f = Math.floor((celsiusNumber * 9) / 5 + 32)
  return f
}

export function formatGoogleDriveImage(image) {
  if (image && image.includes("drive.google.com")) {
    let imageId = image.split("/")[5] // gets the image id from the url

    // const formattedImage = `https://drive.google.com/uc?export=view&id=${imageId}`
    const formattedImage = `https://lh3.googleusercontent.com/d/${imageId}`
    return formattedImage
  } else {
    return image
  }
}

export function convertPlantData(oldPlantData) {
  const newPlantData = []
  for (let key in oldPlantData) {
    const category = key
    const plants = oldPlantData[key]
    for (let plant of plants) {
      const plantObj = {
        category: category,
        name: plant,
        size: 0,
        description: "",
        imgUrl: "",
        active: true,
        plantingMonths: [],
      }
      newPlantData.push(plantObj)
    }
  }
  return newPlantData
}

export function sortArrayOfObjects(a, b) {
  if (a > b) {
    return 1
  }
  if (a < b) {
    return -1
  }
  return 0
}

export function getPWADisplayMode() {
  const isStandalone = window.matchMedia("(display-mode: standalone)").matches
  if (document.referrer.startsWith("android-app://")) {
    return "twa"
  } else if (navigator.standalone || isStandalone) {
    return "standalone"
  }
  return "browser"
}
