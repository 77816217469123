import React from "react"
import { Accordion } from "react-bootstrap"
import "./EmployeeInfo.css"

export default function EmployeeInfo({ employee, i }) {
  // const catSet = new Set()
  // const sortBy = (array, arrayProperty) =>
  //   array.sort((first, second) => {
  //     if (first[arrayProperty] < second[arrayProperty]) {
  //       return -1
  //     }
  //     if (first[arrayProperty] > second[arrayProperty]) {
  //       return 1
  //     }
  //     return 0
  //   })

  return (
    <div className="employee-info-container" key={i + employee.id}>
      <div>{employee.photo}</div>
      <div>
        <a href={`tel:${employee.mobile}`}>{employee.mobile}</a>
      </div>
      <a href={`mailto:${employee.email}?subject=Yard To Fork&body=Hi ${employee.firstName},`}>{employee.email}</a>
      {employee.address && (
        <div>
          <a href={`http://google.com/maps/search/?api=1&query=${employee.address}/${employee.city}/${employee.state}`}>{employee.address + ", " + employee.city + ", " + employee.state}</a>
        </div>
      )}
      <div>
        <Accordion>
          {/* <Accordion.Item eventKey={i + 1000}>
            <Accordion.Header bsPrefix="custom-plants-accordion" id="plants-header">
              Messages
            </Accordion.Header>
            <Accordion.Body>
              {employee.messages.map((i, message) => {
                return (
                  <div key={i + message}>
                    <p >{message.message}</p>
                    <span>{message.timeStamp}</span>
                  </div>
                )
              })}
            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>
      </div>
    </div>
  )
}
