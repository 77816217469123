import { timeOfDayGreeting as greeting } from "../../../../util/helper";
import mainLogo from "../../../../assets/main-logo.png";
import "../../Home/Home.css";
import WeatherComponent from "../../../../components/Weather/WeatherComponent";
import HomeButtonsContainer from "../../../../components/Containers/HomeButtonsContainer"

export default function AdminHome({ user }) {
	return (
		<div className="d-flex flex-column flex-grow-1 home-container">
			<div className="d-flex justify-content-center">
				<div className="main-logo-container">
					<img
						src={mainLogo}
						alt="garden"
						id="main-logo"
						className="m-auto p-4"
					/>
				</div>
			</div>
			<div className="home-spacer">
				<h1 className="home-user-title">
					{greeting() + " " + user.firstName + "!"}
				</h1>
				<WeatherComponent />
			</div>
			<HomeButtonsContainer user={user} />
		</div>
	);
}

