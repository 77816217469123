import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Accordion, FormControl } from "react-bootstrap";
import LoadingSpinner from "../../../../components/Loading/LoadingSpinner";

import { AiOutlineEdit } from "react-icons/ai";
import { getPlantsInfo, updateCategories, updatePlantsInfo } from "../../../../firestore/firestore";
import { Link } from "react-router-dom";
import "./EditPlants.css";



export default function EditPlants() {
    const [plantsData, setPlantsData] = useState("");
    const [addCategory, setAddCategory] = useState(false);
    const [categories, setCategories] = useState();
    const [newCategory, setNewCategory] = useState("");

    const catRef = useRef()
    const plantsRef = useRef()
    plantsRef.current = plantsData //used for unmounting useEffects to keep track of state without having to update on every change
    catRef.current = categories

    const allPlants = []; // used to render plants in alphabetical order

    useEffect(() => {
        getPlantsInfo()
            .then((plantsData) => {
                const categories = [];
                for (let category of plantsData.categories) {
                    categories.push(category);
                }

                setCategories(categories);
                setPlantsData(plantsData.plants);
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
      
      return () => {
        if(catRef.current && plantsRef.current){
        
          updateCategories(catRef.current)
          updatePlantsInfo(plantsRef.current)
         
        }
      }
    }, [])

    function updatePlantsCategories(oldCat, newCat) {
        const newPlantsData = plantsData.map((plant) => {
            if (plant.category === oldCat) plant.category = newCat;
            return plant;
        });
        setPlantsData(newPlantsData);
    }

    function handleCategoryChange(e, index) {
        e.preventDefault();
        const newCategoryName = e.target.value;
        const oldCategoryName = categories[index];
        let newCategories = [...categories];
        newCategories[index] = newCategoryName;
        if(!newCategoryName){
          newCategories = newCategories.filter(cat => cat !== "")
        }
        setCategories(newCategories);
        updatePlantsCategories(oldCategoryName, newCategoryName);
        
    }

    function handleNewCategoryChange(e) {
        e.preventDefault();
        setNewCategory(e.target.value);
    }

    function addNewCategory(e) {
        if (newCategory) setCategories([...categories, newCategory]);
        setNewCategory();
        setAddCategory(false);
    }

    if (plantsData) {
        return (
            <>
                <Accordion className="w-100">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => setAddCategory(false)}>
                            Edit Categories
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="d-flex flex-column  w-100 h-100">
                                {categories.map((category, i) => {
                                    return (
                                        <FormControl
                                            className="w-100"
                                            key={i + category}
                                            value={category}
                                            autoFocus
                                            onChange={(e) =>
                                                handleCategoryChange(e, i)
                                            }
                                        />
                                    );
                                })}
                                {addCategory && (
                                    <FormControl
                                        type="text"
                                        className="w-100"
                                        value={newCategory}
                                        onChange={handleNewCategoryChange}
                                        onBlur={addNewCategory}
                                    />
                                )}
                                <button
                                    className="btn-add"
                                    onClick={() => setAddCategory(!addCategory)}
                                >
                                    Add Category
                                </button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Edit Plants</Accordion.Header>
                        <Accordion.Body>
                            <div className="d-flex flex-column  w-100 h-100">
                                {plantsData.forEach(
                                    (plant) => allPlants.push(plant.name) // create list of just plants that can be sorted and displayed
                                )}

                                {allPlants.sort().map((plantName, i) => {
                                    return (
                                        <Link
                                            to={`../edit-plant/${plantName}`}
                                            className="text-start pb-2 react-router-link"
                                            key={i + plantName}
                                            state={{
                                                categories: categories,
                                                plantsData: plantsData,
                                            }}
                                        >
                                            {plantName}
                                            <AiOutlineEdit />
                                        </Link>
                                    );
                                })}

                                <Link
                                    to={"../add-plant"}
                                    state={{
                                        categories: categories,
                                        plantsData: plantsData,
                                    }}
                                >
                                    <button className="btn-add">
                                        Add Plant
                                    </button>
                                </Link>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </>
        );
    } else {
        return <LoadingSpinner />;
    }
}
