import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import "./Navigation.css"
// import { FiSettings } from "react-icons/fi";
import { AiOutlineLogout } from "react-icons/ai"
import { AiOutlineHome } from "react-icons/ai"
import * as auth from "../../auth/auth"

export default function Footer({ lastName }) {
  const location = useLocation()

  function logout() {
    auth.logout()
  }
  const inInitialSetup = location.pathname.includes("initial")

  return (
    <Navbar fixed="top" className="custom-navbar">
      <Link to="/" style={{ textDecoration: "none", color: "white" }}>
        <span className="nav-logo">{lastName}</span>
      </Link>

      <Nav>
        <Nav.Link as={Link} to="/" href="/" className="link d-flex align-items-center non-icon">
          <AiOutlineHome className="mx-1 icon text-white" hidden={inInitialSetup} />
          <span className="non-icon">Home</span>
        </Nav.Link>
        {/* <Nav.Link
                    as={Link}
                    to="/settings"
                    href="/settings"
                    className="link d-flex align-items-center non-icon"
                >
                    <FiSettings className="mx-1 icon text-white" />
                    <span className="non-icon">Settings</span>
                </Nav.Link> */}
        {/* <WeatherComponent /> */}
        <Nav.Link as={Link} to="/" href="/" onClick={logout} className="link d-flex align-items-center non-icon">
          <AiOutlineLogout className="mx-1 icon text-white" />
          <span className="non-icon">Logout</span>
        </Nav.Link>
      </Nav>
    </Navbar>
  )
}
