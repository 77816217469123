/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css"
import { Routes, Route, useNavigate } from "react-router-dom"
import "firebase/compat/auth"
import SignInPage from "./Views/Login/sign-in/SignInPage"
import SignUpPage from "./Views/Login/sign-up/SignUpPage"
import { useEffect, useState } from "react"
import { onAuthStateChanged } from "firebase/auth"
import { auth } from "./Config/firebaseConfig"
import Main from "./Views/Main/Main"
import { selectUserId, setActiveUser } from "./features/user/userSlice"
import { getUserInfo } from "./firestore/firestore"
import { useDispatch, useSelector } from "react-redux"
import ResetPasswordPage from "./Views/Login/sign-in/ResetPasswordPage"

function App() {
  const [userIsLoaded, setUserIsLoaded] = useState(false)
  const [userData, setUserData] = useState("")
  const userId = useSelector(selectUserId)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      // If there is no user id from redux (should only occur with new user), get user info from db and load it into redux.

      if (!userId && currentUser) {
        getUserInfo(currentUser.uid)
          .then((user) => {
            if (user) {
              dispatch(setActiveUser(user))
              setUserIsLoaded(true)
            }
          })
          .catch((err) => {
            console.error(err.message)
            navigate("/")
          })
      } else if (userId || userData) {
        setUserIsLoaded(true)
      } else {
        // if there is no current user logged in, redirect to sign-in
        navigate("/")
      }
    })

    return () => {
      unsubscribe()
    }
  }, [userIsLoaded, userId, userData])

  return (
    <div className="App">
      <div className="m-0 p-0  container-fluid ">
        {!userIsLoaded ? (
          <Routes>
            <Route path="/" element={<SignInPage />}></Route>
            <Route path="/sign-up/*" element={<SignUpPage setUserData={setUserData} />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
          </Routes>
        ) : (
          <Routes>
            <Route index path="/*" element={<Main />}></Route>
          </Routes>
        )}
      </div>
    </div>
  )
}

export default App
