import React from "react"
import { useState, useEffect, useRef } from "react"
import { Accordion, FormControl, InputGroup } from "react-bootstrap"
import LoadingSpinner from "../../../components/Loading/LoadingSpinner"
import CategoryButton from "../../../components/Selection/CategoryButton"
import Selection from "../../../components/Selection/Selection"
import { getPlantsInfo, updateSpecialtyRequests } from "../../../firestore/firestore"
import "./Preferences.css"
import "../../../components/Selection/Selection.css"
import { useDispatch, useSelector } from "react-redux"
import { selectSpecialtyRequests, selectUserId, setSpecialtyRequests } from "../../../features/user/userSlice"

export default function Preferences({ user }) {
  const [plantData, setPlantData] = useState()
  const [categories, setCategories] = useState()
  const [currentCategory, setCurrentCategory] = useState("")
  const [currentTarget, setCurrentTarget] = useState(null)
  const [checked, setChecked] = useState(user.plants)
  const [specialtyRequest, setSpecialtyRequest] = useState(useSelector(selectSpecialtyRequests))
  const accordionRef = useRef()
  const dispatch = useDispatch()
  const userId = useSelector(selectUserId)

  // ONLY USE OPERATION BELOW TO OVERRIDE plant data with original plant data. WARNING...it will overide current plant data.
  // const transformedPlantData = convertPlantData( < data to convert > )
  // updatePlantsInfo(< array of plant objects >);

  useEffect(() => {
    const getPlantData = async () => {
      const plantData = await getPlantsInfo()
      const newPlantData = plantData.plants
      const newCategories = plantData.categories

      setPlantData(newPlantData)
      setCategories(newCategories)
    }
    getPlantData().catch(console.err)
  }, [])

  useEffect(() => {
    if (!currentTarget) {
      return
    }
    accordionRef.current = currentTarget

    setTimeout(
      () =>
        accordionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      200
    )
  }, [currentTarget])

  function handleAccordion(e) {
    const element = e.currentTarget
    const category = element.querySelector('button').textContent
    const closed = element.querySelector('button').classList.length === 1 && e.target.classList[0] === "accordion-button";
    if(!closed){
      setCurrentCategory(category)
    } else {
      // if accordian was cliced to close...
      setCurrentCategory("")
    }
    setCurrentTarget((prevTarget) => (prevTarget = element))
  }

  function handleSpecialtyRequest(e) {
    setSpecialtyRequest(e.target.value)
  }

  function handleSaveSpecialtyRequest() {
    // save to redux and db
    dispatch(setSpecialtyRequests({ specialtyRequests: specialtyRequest }))
    updateSpecialtyRequests(userId, specialtyRequest)
  }

  if (plantData && categories) {
    return (
      <>
      {currentCategory && <div className="current-category-title">{currentCategory}</div>}
        <Accordion className="custom-accordion">
          {categories.map((category, i) => {
            return (
              <div key={i + category} ref={accordionRef} onClick={handleAccordion}>
                {/* Create accordian item with plant offerings. */}
                <CategoryButton eventKey={i} category={category} id={i + category}>
                  <Selection
                    plantData={plantData.filter((p) => p.category === category).sort((a, b) => a.name.localeCompare(b.name))} // load plants for current category
                    checked={checked}
                    setChecked={setChecked}
                    // Update checked plants with current users previously checked plants
                    userId={user.id}
                  />
                </CategoryButton>
              </div>
            )
          })}
          <Accordion.Item eventKey="100" className="accordion-item">
            <Accordion.Header className="accordion-item">Specialty Requests</Accordion.Header>
            <Accordion.Body>
              <InputGroup>
                <FormControl value={specialtyRequest} type="text" name="specialty" id="specialty" onChange={handleSpecialtyRequest} onBlur={handleSaveSpecialtyRequest} />
              </InputGroup>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
    )
  } else {
    return <LoadingSpinner />
  }
}
