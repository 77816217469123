import React, { useEffect, useRef, useState } from "react"
import { Accordion } from "react-bootstrap"
import EmployeeInfo from "../../../../components/EmployeeInfo/EmployeeInfo"
import LoadingSpinner from "../../../../components/Loading/LoadingSpinner"
import EmployeeAccordion from "../../../../components/Selection/EmployeeAccordion"
import { getAllEmployeeInfo } from "../../../../firestore/firestore"
import PullToRefresh from "react-simple-pull-to-refresh"
import { sortArrayOfObjects } from "../../../../util/helper"

export default function Employees() {
  const [employees, setEmployees] = useState("")
  const [currentTarget, setCurrentTarget] = useState(null)
  const accordionRef = useRef()

  useEffect(() => {
    handleRefresh()
  }, [])

  useEffect(() => {
    if (!currentTarget) {
      return
    }
    accordionRef.current = currentTarget

    setTimeout(
      () =>
        accordionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      200
    )
  }, [currentTarget])

  async function handleRefresh() {
    const getEmployeeData = async () => {
      const employeeData = await getAllEmployeeInfo()
      const sortedEmployeeInfo = employeeData.sort((a, b) => sortArrayOfObjects(a.lastName, b.lastName))
      setEmployees(sortedEmployeeInfo)
      return sortedEmployeeInfo
    }
    getEmployeeData().catch((err) => console.log(err))
  }

  function handleAccordion(e) {
    const element = e.currentTarget
    setCurrentTarget((prevTarget) => (prevTarget = element))
  }
  if (employees) {
    return (
      <>
        <PullToRefresh onRefresh={handleRefresh} pullDownThreshold={90} resistance={3}>
          <Accordion className="custom-accordion">
            {employees.map((employee, i) => {
              return (
                <div key={i + employee.id} ref={accordionRef} onClick={handleAccordion}>
                  <EmployeeAccordion eventKey={i} employee={employee} id={i + employee.id}>
                    <EmployeeInfo employee={employee} i={i} />
                  </EmployeeAccordion>
                </div>
              )
            })}
          </Accordion>
        </PullToRefresh>
      </>
    )
  } else {
    return <LoadingSpinner />
  }
}