
import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectActiveUser } from '../../../../features/user/userSlice'

export default function AdminSettings() {

  const activeUser = useSelector(selectActiveUser)
  const navigate = useNavigate()
  
  useEffect(() => {
    if(activeUser.role !== "admin") {
      navigate()
    }
  })

  return (
    <>
    <h1 className='pt-3'>Settings</h1>
    <div className='pt-5'>{activeUser.role}</div>
    </>
  )
}
