import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectUserId } from '../../../features/user/userSlice'
import { updateMessages } from '../../../firestore/firestore'
import InfoModal from '../../../components/Modals/InfoModal'
import messageSentIcon from '../../../assets/icons8-chat-message-sent-96.png'

export default function MessageYTF() {

  const [localMessage, setLocalMessage] = useState("")
  const [showInfoModal, setShowInfoModal] = useState(false)
  const userId = useSelector(selectUserId)

  function handleChange(e) {
    const msg = e.target.value
    setLocalMessage(msg)
  }

  function handleSubmit() {
    updateMessages(userId, localMessage)
    setShowInfoModal(true)
    
  }

  function handleCloseInfoModal() {
    setShowInfoModal(false)
  }

  return (
    <div className='mt-4'>
    <InfoModal show={showInfoModal} close={handleCloseInfoModal} modalInfo={["Message Submitted", messageSentIcon ,"We will get back to you as soon as we can."]} />
    <h1>Under Construction</h1>
    
    <Form>
      <Form.Group>
        <Form.Label>Text Area</Form.Label>
        <Form.Control as={'textarea'} onChange={handleChange} value={localMessage}>

        </Form.Control>
      </Form.Group>
      <Button onClick={handleSubmit}>Submit</Button>
    </Form>
    </div>
  )
}
