import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Form } from "react-bootstrap"
import { useLocation } from "react-router-dom"

import { updatePlantsInfo } from "../../../../firestore/firestore"

import { RiDeleteBin2Line } from "react-icons/ri"
import WarningModal from "../../../../components/Modals/WarningModal"
import { formatGoogleDriveImage } from "../../../../util/helper"

export default function EditPlant() {
  const [showModal, setShowModal] = useState(false)
  const { name } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const warningModalInfo = [/*Title*/ ` ${name}`, /*Description*/ `If you delete ${name} you will lose all information associated with it.`]
  // categories = array of strings, plantsData = array of plant objects
  const { categories, plantsData } = location.state
  const currentPlant = plantsData.find((plant) => plant.name === name)
  
  function handleFormSubmit(e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    const plantIndex = plantsData.findIndex((plant) => plant.name === name)
    plantsData[plantIndex] = {
      active: true,
      category: formData.get("category"),
      description: formData.get("plantDescription"),
      imgUrl: formatGoogleDriveImage(formData.get("plantImgUrl")),
      link: formData.get("plantLink"),
      name: formData.get("plantName"),
      plantingMonths: [Number(formData.get("seasonStart")), Number(formData.get("seasonEnd"))],
      size: Number(formData.get("plantSize")),
    }
    
    
    updatePlantsInfo(plantsData)
      .then(() => 
        alert(`${name} has been updated`), 
        navigate(-1)
     
      )
      .catch((err) => {
        console.log(err)
        alert("An error occured, Please try again later.")
      })
  }

  function handleDelete() {
    setShowModal(true)
  }

  function confirmDelete() {
    const newPlantsData = plantsData.filter((plant) => plant.name !== name)

    setShowModal(false)

    updatePlantsInfo(newPlantsData)
      .then(() => {
        alert(`${name} has been updated`)
        navigate(-1)
      })
      .catch((err) => {
        console.log(err)
        alert("An error occured, Please try again later.")
      })
  }

  return (
    <div className="text-start mt-4">
      <div className="d-flex justify-content-between">
        <h1>Edit {name} </h1>
        <span className="medium-font" onClick={handleDelete}>
          <RiDeleteBin2Line className="text-danger" />
        </span>
      </div>
      <Form onSubmit={handleFormSubmit}>
        <Form.Group className="my-3" controlId="plantCategory">
          <Form.Label>Category</Form.Label>
          <Form.Select
            aria-label="Default select example"
            // id="plant-category"
            className="custom-select"
            name="category"
            defaultValue={currentPlant.category}
          >
            {categories.map((cat, i) => {
              return <option key={i + cat}>{cat}</option>
            })}
          </Form.Select>
        </Form.Group>

        <Form.Group className="my-3" controlId="plantName">
          <Form.Label>Plant Name</Form.Label>
          <Form.Control
            // id="plantName"
            name="plantName"
            defaultValue={currentPlant.name}
            required
          />
        </Form.Group>

        <Form.Group className="my-3" controlId="plantSize">
          <Form.Label>Plant Size</Form.Label>
          <aside className="plant-aside">Number of square inches the plant needs.</aside>
          <Form.Control
            // id="plantSize"
            name="plantSize"
            placeholder="10"
            type="number"
            defaultValue={currentPlant.size}
            min={1}
            max={10000}
            step={1}
          />
        </Form.Group>
        <Form.Group className="my-3" controlId="plantingSeason">
          <Form.Label>Growing Season</Form.Label>
          <div className="d-flex justify-content-between">
            <div>
              <aside className="plant-aside">Starting Month</aside>
              <Form.Select name="seasonStart" placeholder="1" type="select" defaultValue={currentPlant.plantingMonths ? currentPlant.plantingMonths[0] : 0}>
                <option value={0}>Jan</option>
                <option value={1}>Feb</option>
                <option value={2}>Mar</option>
                <option value={3}>Apr</option>
                <option value={4}>May</option>
                <option value={5}>Jun</option>
                <option value={6}>Jul</option>
                <option value={7}>Aug</option>
                <option value={8}>Sep</option>
                <option value={9}>Oct</option>
                <option value={10}>Nov</option>
                <option value={11}>Dec</option>
              </Form.Select>
            </div>
            <div>
              <aside className="plant-aside">Ending Month</aside>
              <Form.Select name="seasonEnd" placeholder="1" type="select" defaultValue={currentPlant.plantingMonths ? currentPlant.plantingMonths[1] : 11}>
                <option value={0}>Jan</option>
                <option value={1}>Feb</option>
                <option value={2}>Mar</option>
                <option value={3}>Apr</option>
                <option value={4}>May</option>
                <option value={5}>Jun</option>
                <option value={6}>Jul</option>
                <option value={7}>Aug</option>
                <option value={8}>Sep</option>
                <option value={9}>Oct</option>
                <option value={10}>Nov</option>
                <option value={11}>Dec</option>
              </Form.Select>
            </div>
          </div>
        </Form.Group>
        <Form.Group className="my-3" controlId="plantImage">
          <Form.Label>Image Url</Form.Label>
          <aside className="plant-aside">Get image address not image link address.</aside>
          <Form.Control
            // id="plantImgUrl"
            name="plantImgUrl"
            placeholder="http://exampleplantimage.com"
            defaultValue={currentPlant.imgUrl}
          />
        </Form.Group>
        <Form.Group className="my-3" controlId="plantDescription">
          <Form.Label>Description</Form.Label>
          <aside className="plant-aside">Short description of the plant.</aside>
          <Form.Control
            // id="plantDescription"
            name="plantDescription"
            as="textarea"
            maxLength={195}
            defaultValue={currentPlant.description}
          />
        </Form.Group>
        <Form.Group className="my-3" controlId="plantLink">
          <Form.Label>Link</Form.Label>
          <aside className="plant-aside">Add an optional link for more info on the plant.</aside>
          <Form.Control placeholder="https://example.com" name="plantLink" type="url" defaultValue={currentPlant.link} />
        </Form.Group>
        <button className="btn-add mx-auto" type="submit">
          Save
        </button>
      </Form>
      <WarningModal show={showModal} close={() => setShowModal(false)} confirmDelete={confirmDelete} modalInfo={warningModalInfo} />
    </div>
  )
}
