import React, { useState } from "react"
import { useEffect } from "react"
import { Form } from "react-bootstrap"
import { AiOutlineCamera } from "react-icons/ai"
import { useDispatch } from "react-redux"
import { setPlants } from "../../features/user/userSlice"
import { updateUserPlants } from "../../firestore/firestore"
import InfoModal from "../Modals/InfoModal"
import "./Selection.css"

export default function Selection({ checked, setChecked, plantData, userId }) {
  // On load, fetch plants, populate chosen plants array, look for plants with the same names and check them.
  // Manipulate chosen plants array with changes, then send to db before page change or refresh.
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [modalInfo, setModalInfo] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    updateUserPlants(userId, checked)
    dispatch(setPlants({ plants: checked }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked])

  function handleChange(e, plantObject) {
    const isChecked = e.currentTarget.checked

    if (isChecked) {
      setChecked((prevChecked) => [...prevChecked, plantObject])
    } else {
      setChecked((prevChecked) => prevChecked.filter((currentPlant) => currentPlant.name !== plantObject.name))
    }
  }

  const handleCloseInfoModal = () => setShowInfoModal(false)
  const handleShowInfoModal = (name, image, description, link) => {
    setShowInfoModal(true)
    setModalInfo([name, image, description, link])
  }

  return (
    <div className="w-100 d-flex justify-content-start selection-container" style={{ fontFamily: "var(--main-font)" }}>
      <Form>
        {plantData.map((plant, i) => {
          return (
            <div key={i + plant.name} className="d-flex align-items-center">
              <Form.Check className="customCheckbox" label={plant.name} name={plant.name} checked={checked.some((p) => p?.name === plant.name)} onChange={(e) => handleChange(e, plant)}></Form.Check>
              <span onClick={() => handleShowInfoModal(plant.name, plant.imgUrl, plant.description, plant.link)}>
                <div className="photo-icon">
                  <AiOutlineCamera />
                </div>
              </span>
            </div>
          )
        })}
      </Form>
      <InfoModal show={showInfoModal} close={handleCloseInfoModal} modalInfo={modalInfo} />
    </div>
  )
}
