import React from "react";
import { Spinner } from "react-bootstrap";
import "./LoadingSpinner.css";

export default function LoadingSpinner() {
    return (
        <div className="center">
            <Spinner animation="border" variant="info" />
        </div>
    );
}
