import React, { useState } from "react"
import InputGenerator from "../../../components/Login/InputGenerator"
import mainLogo from "../../../assets/main-logo.png"
import { Link, useNavigate } from "react-router-dom"
import { resetFirebasePassword } from "../../../auth/auth"
import { loginLinkStyle } from "../../../styles"

export default function ResetPasswordPage() {
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)
  const navigate = useNavigate()

  function handleChange(e) {
    setEmail(e.target.value)
  }

  function handlePasswordReset() {
    resetFirebasePassword(email)
      .then(() => {
        setSuccess((prevState) => !prevState)
        setTimeout(() => {
          navigate(-1)
        }, 4000)
      })
      .catch((err) => alert(err.message))
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 sign-in-background">
      <div className=" d-flex flex-column justify-content-center align-items-center sign-in">
        <img src={mainLogo} alt="Main logo" className="w-50 m-2" />
        <div className="password-reset-instructions" style={success ? { background: "lightgreen" } : {}}> 
          {success ? <p>{`An email has been sent to ${email}. Please check your email for instructions.`}</p> : <p>Enter the email associated with your account to send a password reset email</p>}
        </div>
        <InputGenerator
          inputs={[
            {
              label: "Email",
              type: "email",
              value: email,
            },
          ]}
          handleChange={handleChange}
        />
        <button className="sign-in-button" onClick={handlePasswordReset} disabled={success}>
          Send
        </button>
      </div>
      <br></br>

      <Link to={"/sign-up"} style={loginLinkStyle}>
        Register
      </Link>
      <Link to={"/"} style={loginLinkStyle}>
        Login
      </Link>
    </div>
  )
}
