import React from "react"
import { IconContext } from "react-icons"
import { FiMail, FiMessageSquare, FiPhoneCall } from "react-icons/fi"
import "./ContactPage.css"

export default function ContactPage() {
  return (
    <div className="contact-page-container">
      <IconContext.Provider value={{ size: 90, color: "var(--ytf-green)" }}>
        <div className="contact-icons-container">
        <div className="contact-button">
          
          <a href="tel:+19169057027">
            <FiPhoneCall />
          </a>
        </div>
        </div>
        <div className="contact-icons-container">

          <div className="contact-button">
            <a href="mailto: service@yardtofork.com">
              <FiMail />
            </a>
          </div>
        </div>
        <div className="contact-icons-container">
          <div className="contact-button">
            <a href="sms:+19169057027">
              <FiMessageSquare />
            </a>
          </div>
        </div>
      </IconContext.Provider>
    </div>
  )
}
