import { Link } from "react-router-dom";
import "./HomeButton.css";

export default function HomeButton({ to, buttonName }) {
    return (
        <Link to={to} className="d-flex flex-grow-1 home-page-link">
            <button
                className="flex-grow-1 align-middle home-page-button"
                name={buttonName}
            >
                {buttonName}
            </button>
        </Link>
    );
}
